// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
// @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "./newVariable.scss";

/*********** Reset Styles starts here************/
body {
  background-color: #191e2b;
  font-family: $bodyFont;
  color: $color-white;
  line-height: unset;
  font-weight: $font-weight-normal;
}

.colorBitterSweet {
  color: $color-bitter-sweet;
}

.colorMintGreen {
  color: $color-mint-green;
}

h6 {
  margin-bottom: 0px;
}

p {
  margin-bottom: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

i {
  cursor: pointer;
}

button {
  cursor: pointer !important;
}

button > div > h5 {
  margin: 0px;
}

input {
  background-color: #16171b;
  padding: 0 10px;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:focus {
  background-color: $color-bg-light;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: $color-bg-light;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  outline: none !important;
  border-radius: 10px;
}
/*********** Reset Styles ends here************/

/*********** Global Styles starts here************/
/*********** Page Layout ************/
.pageLayout {
  width: 100%;
  display: flex;

  .mainCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

/*********** Container ************/
.container {
  max-width: 1280px;
  margin: 0 auto;
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
}
/*********** Global Styles ends here************/

/*********** Home Page starts here ************/
.home-div {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.home-page-one {
  position: absolute;
  left: 0px;
  filter: contrast(0.8);
}

.home-page-two {
  position: absolute;
  bottom: 0px;
  right: 0px;
  filter: contrast(0.8);
}

.right-image {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 20vw;
  height: 50vh;
  filter: contrast(0.5);
  background-size: 100% 100%;
}

.left-image {
  position: fixed;
  width: 23vw;
  height: 60vh;
  filter: contrast(0.5);
  background-size: 100% 100%;
}
/*********** Home Page ends here ************/

/*********** Normal List starts here ************/
.nft-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
}

.normal-list-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.see-more-button {
  display: block;
  margin: 20px auto;
}
/*********** Normal List ends here ************/

/*********** NFTS & Inventory page starts here ************/
.items-list-div {
  flex: 0.8;
  padding: 20px;
  background-color: #191e2b;
}

.items-list-header {
  display: initial;
  font-family: $headingFont;
}

.header-hr {
  border-top: 2px solid $color-primary;
  width: 30px;
  margin: 0px 0px 8px 0px;
}

.header-text {
  font-size: 40px;
  font-weight: 400;
  color: $color-primary;
  margin-bottom: -24px;
}

.item-list-sort {
  position: sticky;
  top: $headerHeight; // Height of Header
  z-index: 50;
  display: flex;
  justify-content: flex-end;
  // background-color: $color-bg-light;
  padding: 24px 0 0 0;
  margin-bottom: 24px;
}

.sort-list-marketplace {
  width: 250px;
  margin-bottom: 20px;
  font-size: 18px;
}
/*********** NFTS & Inventory page ends here ************/

/*********** Single NFT starts here ************/
.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.single-nft-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  gap: 20px;
}
.single-nft-content-div {
  display: flex;
  //   align-items: flex-end;
  //   justify-content: center;
  //   margin: auto;
  //   margin-top: 30px;
  gap: 20px;
}

.nft-info-main-div {
  width: 50%;
  .nft-info:first-child {
    font-family: $headingFont;
    font-size: 22px;
    font-weight: 700;
    color: $color-text-primary;
    margin-bottom: 12px;
  }
}

.nft-info {
  margin-bottom: 10px;
  font-size: 16px;
  span:first-child {
    color: $color-text-gray;
  }
  span:last-child {
    color: $color-text-primary;
  }
}

.nft-button {
  background: $color-bg-gray;
  padding: 24px;
  border-radius: 10px;
  margin: 20px 0;
  button {
    background-color: $color-primary;
    color: $color-text-primary;
    font-weight: 700;
  }
}

.single-nft-attr-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  text-align: center;
  font-size: 14px;
}

.single-nft-attr-loading-div {
  height: 180px;
  background-color: $color-bg-gray;
  border-radius: 5px;
  display: grid;
  place-items: center;
}

.nft-attr-div {
  background-color: $color-bg-gray;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  span:first-child {
    font-size: 12px;
  }
  span:last-child {
    margin-top: 5px;
    color: $color-text-gray;
  }
}

.single-nft-props-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 1px solid #b8bbc7;
  padding: 16px 0 0 0;
  margin: auto;
  margin-top: 16px;

  > h5 {
    color: $color-text-gray;
  }
}

.nft-below-card-property {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.3em;
}

.nft-props-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.nft-card-property-new {
  background-color: $color-bg-gray;
  border-radius: 5px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

.nft-card-property {
  background-color: $color-bg-gray;
  border-radius: 5px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 49%;
  margin-top: 15px;
  text-align: center;

  span:last-child {
    margin-top: 5px;
    color: $color-text-gray;
  }
  &:first-child {
    width: 100%;
    text-align: center;
    line-height: 1.5;
  }
}

.price-text {
  color: $color-text-primary;
  font-weight: 700;
  margin-bottom: 16px;

  > .price-logo {
    width: 28px;
    margin-right: 10px;
  }
}
/*********** Single NFT ends here ************/

/*********** Antd starts here ************/
.ant-select-focused {
  border: 1px solid $borderColor !important;
}

.ant-slide-up-enter .ant-slide-up-enter-start .ant-slide-up {
  background-color: #2e3136;
}

// Select starts here-------------------------------------------------------
.ant-select {
  color: $color-text-gray;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #1c1f27;
  border: 1px solid $color-border;
}
// Select ends here-------------------------------------------------------

.ant-input-number {
  border: 1px solid $color-border-secondary;
}

.ant-input-number-input {
  background-color: $color-bg-dark;
  color: $color-text-primary;
}

.ant-select-arrow {
  color: $color-text-gray;
}

// .ant-select-item-option {
//   display: flex;
//   background: #2e3136;
//   color: #8792b2;

//   &:hover {
//     background-color: #1c1f27;
//   }
// }

// .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   color: #ebebeb;
//   background-color: #1c1f27;
// }

// .ant-select-item-option-selected {
//   color: #ebebeb;
// }

// Collapse starts here-------------------------------------------------------
.ant-collapse {
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border-color: $color-border-secondary;
  color: $color-text-gray;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  // background-color: $color-bg-dark;
  background-color: $color-new-bg-dark;
  color: $color-text-primary;
  font-weight: 700;
}
.ant-collapse-content {
  background-color: $color-new-bg-dark;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content > .ant-collapse-content-box > .ant-input-number-input {
  background-color: $color-new-bg-dark;
}
.ant-collapse-content-active {
  border: none;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0;
}
// Collapse ends here-------------------------------------------------------

.ant-slider:hover .ant-slider-track,
.ant-slider-track {
  background-color: $color-coral;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle {
  border-color: $color-coral;
}
/*********** Antd ends here ************/

// @media start

@media (max-width: 500px) {
  .sort-list-marketplace {
    width: 213px;
  }
  .single-nft-content-div {
    align-items: center;
    flex-direction: column;
  }
  .nft-info-main-div {
    width: 100%;
  }
  .nft-below-card-property {
    grid-template-columns: 1fr 1fr;
  }
  // .home-div{
  //   position: relative;
  //   z-index: -1;
  // }
}

@media (min-width: 1700px) {
  .wh100 {
    height: calc(100vh - 80px);
  }
}

@import "../../Styles/newVariable.scss";

.network-span {
  text-align: center;
  line-height: 1.5;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: $headerHeight;
  z-index: 1000;
  padding: 0 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: $color-bg-dark;
  background-color: $color-new-bg-dark;
  box-shadow: none;
  font-family: $headingFont;
}

.header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-border;
  height: 4px;
}

.sidebarItem {
  z-index: 999;
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}

.headerItemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.headerItemLeft span {
  padding-top: 0.2em;
  color: #b84aff;
  font-size: 12px;
  font-style: italic;
}

.headerItemCenter {
  display: flex;
  justify-content: center;
  span {
    font-weight: 700;
  }
}

// Menu Item selected style (active style)
.selected {
  border-bottom: 3px solid $color-primary;
  i,
  span {
    color: $color-primary;
  }
}

.headerItemRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.identiconImg {
  border-radius: 50%;
  width: fit-content;
  border: 3px solid $color-primary;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.metamaskLogo {
  height: 30px;
  margin-right: 5px;
}

.connectBtn {
  font-weight: 700;
  background-color: $color-primary;
  border-radius: 5px;
  color: $color-white;
  border: none;
  margin-right: 20px;
  padding: 10px;
}

// Antd Toggle Switch
.ant-switch-inner {
  display: block;
  margin: 0px 5px;
  color: #fff;
  font-size: 12px;
  -webkit-transition: margin 0.2s;
  transition: margin 0.2s;
}

.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 7rem;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  // background-color: $color-coral;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
  margin-right: 20px;
}

.ant-switch-checked {
  background-color: $color-primary;

  .ant-switch-inner {
    margin-right: 10px;
  }
}

.ant-switch:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  content: "";
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.mobile {
  display: none !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0e1118 !important;
}
.ant-menu.ant-menu-dark {
  background-color: #0e1118 !important;
}
.toggleButton-mobileView {
  // margin-top: 0em !important;
  z-index: 99;
  position: fixed;
  right: 0;
}

@media (max-width: 500px) {
  .headerItemRight {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .header {
    padding: 0 1em;
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none;
  }
}

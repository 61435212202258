$color-black: #000000;
$color-dodger-blue: #2abfff;
$color-dodger-blue-btn-hover: #00acf6;
$color-dodger-blue-darker: #007bff;
$color-white: #ffffff;
$color-bitter-sweet: #ff6363;
$color-bitter-sweet-darker: #ff4545;
$color-mint-green: #8ae261;
$color-mint-green-darker: #77dd48;
$color-mint-green-darkest: #334131;
$color-coral: #ff7e45;
$color-coral-darker: #ff631e;
$color-woodsmoke: #16171b;
$color-shark: #242529;
$color-mind-gray: #636570;
$color-french-gray: #b8bbc7;
$color-abbey: #4a4c55;
$color-mine-shaft: #313131;
$color-primary-text-color: #f5f5f5;
$color-vulcan: #37393f;
$color-vulcan-darker: #1f2024;
$color-kingfisher-daisy: #5d2a86;
$color-sunglow: #fed12c;
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-normal-plus: 500;
$font-weight-bold: 600;

@import "../../Styles/newVariable.scss";

.card-nft {
  border-radius: 12px;
  z-index: 9;
  height: fit-content;
  cursor: pointer;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  border: 1px solid $color-border;
  border-radius: 10px;
  overflow: hidden;

  .category-header {
    padding: 16px;
    color: $color-text-primary;
    text-align: center;
    font-size: 16px;
    font-family: $bodyFont;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nft-img {
    width: 100%;
    border-bottom: 1px solid $color-border;
  }

  .items-list-header {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 12px;
    background: $color-bg-secondary;
    font-family: $bodyFont;

    span {
      color: $color-text-gray;
      font-size: 12px;
    }

    .prices {
      text-align: right;
      font-weight: bold;

      .matic-price {
        margin-bottom: 4px;
      }
    }
  }

  .price-logo {
    width: 16px;
    margin-right: 4px;
  }

  .buy-now-btn {
    background-color: transparent;
    border: none;
    color: $color-primary;
    padding: 0px 0px 16px 0px;
  }

  &:hover:not(.card-nft-single) {
    border: 1px solid $color-primary;
    // border: 1px solid #6b3084;
    transform: translateY(-10px);
    box-shadow: $box-shadow;

    .category-header {
      background-color: $color-card;
      color: white;
    }
  }
}

.noHover {
  transform: none !important;
}

.img-div {
  position: relative;
}

.middle {
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #3300ff1a;
  font-family: $bodyFont !important;
  font-weight: 700;

  > div {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 4px;
    background: #0000009e;
  }
}

.itemImage {
  padding-bottom: 10px;
  padding-left: 12px;
}
@media (max-width: 768px) {
  .card-nft {
    max-width: 285px !important;
  }
  .img-div {
    width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 500px) {
  .img-div {
    width: 100% !important;
    height: auto !important;
  }
}

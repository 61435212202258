@import "../../Styles/newVariable.scss";

.sidebar {
  position: sticky;
  top: $headerHeight; // Height of the header
  height: calc(100vh - #{$headerHeight}); // 100 vh - header's height
  z-index: 10;
  flex: 0.2;
  // background-color: $color-bg-dark;
  background-color: $color-new-bg-dark;

  .sidebarheading {
    border-bottom: 2px solid $color-border;

    .title-div {
      cursor: pointer;
      font-weight: 700;
      font-size: 12px;
      color: $color-text-primary;
      text-align: center;
      width: 100%;
      padding: 24px 16px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .applyBtn {
    display: block;
    width: 100%;
    background-color: $color-primary;
    border-radius: 5px;
    color: $color-white;
    border: none;
    padding: 5px;
    margin-top: 16px;
  }

  .resetBtn {
    display: block;
    width: 100%;
    background-color: $color-primary;
    border-radius: 5px;
    color: $color-white;
    border: none;
    padding: 5px;
    margin-top: 16px;
  }

  .price-range-div {
    display: flex;
    color: $color-text-primary;
    justify-content: space-between;
    align-items: center;
  }

  .open-btn {
    width: 100%;
    height: 60px;
    font-size: 20px;
    background: none;
    border: none;
    color: $color-primary;
    padding: 12px;

    &:hover {
      border: 1px solid $color-primary;
    }

    .btn-text-div {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 500px) {
  .sidebar .price-range-div {
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    width: 135px !important;
  }
  .items-list-div {
    padding: 20px 10px 20px 10px !important;
  }
}

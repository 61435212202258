.footer {
    display: flex;
    height: 10vh;
    background: #0e1118;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-top: 1px solid #4c505d;

    h6 {
        color: #fff;
        margin: 0;
    }

    .powered_section{
        display: flex;
        gap: 0.5em;
        align-items: center;
    }

    img{
        width: 100%;
        max-width: 100px;
    }
}

@media (max-width: 500px){
    .footer {
        flex-direction: column;
        height: 12vh;

        .powered_section{
            margin-top: 0.5em;
        }
    }
    
}
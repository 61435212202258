$color-black: #000000;
$color-dodger-blue: #2abfff;
$color-dodger-blue-btn-hover: #00acf6;
$color-dodger-blue-darker: #007bff;
$color-bitter-sweet-darker: #ff4545;
$color-mint-green-darker: #77dd48;
$color-mint-green-darkest: #334131;
$color-coral: #ff7e45;
$color-coral-darker: #ff631e;
$color-shark: #242529;
$color-mind-gray: #636570;
$color-french-gray: #b8bbc7;
$color-abbey: #4a4c55;
$color-mine-shaft: #313131;
$color-primary-text-color: #f5f5f5;
$color-vulcan: #37393f;
$color-vulcan-darker: #1f2024;
$color-kingfisher-daisy: #5d2a86;
$color-sunglow: #fed12c;
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-normal-plus: 500;
$font-weight-bold: 600;
$borderColor: "#4C505D";
$color-card: #2e3136;

// New--------------------------------------------------------------------------
// Fonts
$bodyFont: "Open Sans", sans-serif;
$headingFont: "Rajdhani", sans-serif;

// Colors

// // Immutable X
// $color-bg-light: #1a1e2b;
// $color-bg-dark: #0e1118;
// $color-bg-secondary: #1c1f27;
// $color-bg-gray: #323742;
// $color-primary: #24d1e9;

// $color-border: #4c505d;
// $color-border-secondary: #2e3136;

// $color-white: #ffffff;
// $color-text-primary: #f6f6f6;
// $color-text-gray: #c1c1c1;

// $box-shadow: #00000099 0px 20px 40px 0px;

// $color-bitter-sweet: #ff6363;
// $color-mint-green: #8ae261;
// $color-woodsmoke: #16171b;

// StackOS NFT Minting
$color-bg-light: #16171b;
$color-bg-dark: #0e0f11;
$color-new-bg-dark: #0e1118;
$color-bg-secondary: #1c1f27;
$color-bg-gray: #323742;
// $color-primary: #2abfff;
// changes
$color-primary: #b84aff;

$color-border: #4c505d;
$color-border-secondary: #2e3136;

$color-white: #ffffff;
$color-text-primary: #f6f6f6;
$color-text-gray: #c1c1c1;

// $box-shadow: 0 0 10px 10px #b84aff;
$box-shadow: 0 0 10px 10px #b84affab;

$color-bitter-sweet: #ff6363;
$color-mint-green: #8ae261;
$color-woodsmoke: #16171b;

// Others
$headerHeight: 70px;
$footerHeight: 10vh;
